<template>
	<div class="p-grid">
		<div class="p-col-12">
            <h3 style="color:#ffcc2a;">{{$t('Reporte')+': '+$t('Pólizas')}} </h3>
		</div>
		<div class="p-col-12">
			<div class="card">
                <DataTable :value="poliza" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm"
                    :paginator="true" :rows="25" >
                    <template #header>
                        <div class="table-header p-grid">
                            <div class="p-col-3">
                                <SelectButton v-model="option" :options="options" :optionLabel="$t('nombre')" aria-labelledby="basic"/>
                            </div>
                            <div class="p-col-7 p-grid">
                                <div class="p-col-2" v-if="option.code==1">
                                    <Dropdown v-model="year" :options="$store.state.years" optionLabel="nombre"></Dropdown>
                                </div>
                                <div class="p-col-2" v-if="option.code==1">
                                    <Dropdown v-model="mes" :options="$store.state.meses" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')"></Dropdown>
                                </div>
                                <div class="p-col-3" v-if="option.code==2">
                                    <InputMask v-model="fecha1" placeholder="DD/MM/YYYY" slotChar="DD/MM/YYYY" mask="99/99/9999"/>
                                </div>
                                <div class="p-col-3" v-if="option.code==2">
                                    <InputMask v-model="fecha2" placeholder="DD/MM/YYYY" slotChar="DD/MM/YYYY" mask="99/99/9999"/>
                                </div>
                                <div class="p-col-2" v-if="option.code>0">
                                    <Dropdown v-model="opcion" :options="opciones" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')"></Dropdown>
                                </div>
                                <div class="p-col-2" v-if="opcion && option.code>0">
                                    <Dropdown v-model="opcion2" :options="opciones2" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')" v-if="opcion"></Dropdown>
                                </div>
                                <div class="p-col-9" v-if="option.code==0">
                                    <InputText v-model="buscar" type="number"/>
                                </div>
                            </div>
                            <div class="p-col-2">
                                <Button :disabled ="$store.state.loading" icon="pi pi-search" class="p-button-rounded p-button-primary p-mr-1" v-tooltip.bottom="$t('Buscar')"  @click="Buscar()"/>
                                <Button :disabled ="$store.state.loading || poliza.length==0" icon="pi pi-download" class="p-button-rounded p-button-warning p-mr-2" @click="VerXLS()"/>
                            </div>
                        </div>
                    </template>
                    <Column :header="$t('Estatus')" field="estado_name" :sortable="true" headerStyle="width: 200px">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.estado==4">
                                <Avatar icon="bi bi-shield-exclamation" class="p-mr-2" size="large" style="background-color:yellow; color: #ffffff"/>
                                {{$t('Verificación  de Pago')}}
                            </div>
                            <div v-else-if="slotProps.data.estado==6">
                                <Avatar icon="bi bi-shield-slash" class="p-mr-2" size="large" style="background-color:red; color: #ffffff"/>
                                {{$t('Anulada')}}
                            </div>
                            <div v-else-if="slotProps.data.vend<0">
                                <Avatar icon="bi bi-shield-slash" class="p-mr-2" size="large" style="background-color:red; color: #ffffff"/>
                                {{$t('Vencida')}}
                                <div v-if="slotProps.data.estado==7">{{$t('Proceso Renovación')}}</div>
                            </div>
                            <div v-else-if="slotProps.data.pendiente>0">
                                <Avatar icon="bi bi-shield-slash" class="p-mr-2" size="large" style="background-color:red; color: #ffffff"/>
                                {{$t('Suspendida')}} <br> ({{$t('Deuda Vencida')}})
                            </div>
                            <div v-else-if="slotProps.data.proceso>0">
                                <Avatar icon="bi bi-shield-slash" class="p-mr-2" size="large" style="background-color:red; color: #ffffff"/>
                                {{$t('Suspendida')}} <br> ({{$t('Pago Vencido')}})
                            </div>
                            <div v-else-if="slotProps.data.estado==7">
                                <Avatar icon="bi bi-shield-shaded" class="p-mr-2" size="large" style="background-color:gray; color: #ffffff"/>
                                {{$t('Proceso Renovación')}}
                            </div>
                            <div v-else>
                                <Avatar icon="bi bi-shield-check" class="p-mr-2" size="large" style="background-color:green; color: #ffffff"/>
                                {{$t('Activa')}}
                            </div>
                        </template>
                    </Column>   
                    <Column field="codigo" :header="$t('Póliza')+' #'" :sortable="true" headerStyle="width: 200px">
                        <template #body="slotProps">
                            {{slotProps.data.codigo}}                              
                        </template>
                    </Column>
                    <Column field="cliente" :header="$t('Cliente')+' #'" :sortable="true" headerStyle="width: 120px">
                        <template #body="slotProps">
                            {{slotProps.data.cliente}}                              
                        </template>
                    </Column>
                    <Column field="nombre" :header="$t('Nombre Completo')+' #'" :sortable="true" headerStyle="width: 150px">
                        <template #body="slotProps">
                            {{slotProps.data.nombre}}                              
                        </template>
                    </Column>
                    <Column field="duracion_name" :header="$t('Duración')" :sortable="true" headerStyle="width: 150px">
                        <template #body="slotProps">
                            <div v-if="i18n.locale() == 'es'">{{slotProps.data.duracion_nombre}}</div>
                            <div v-else>{{slotProps.data.duracion_name}}</div>     
                        </template>
                    </Column>
                    <Column field="activacion" :header="$t('Activación')" :sortable="true" headerStyle="width: 120px">
                        <template #body="slotProps">
                            {{slotProps.data.activacion}}
                        </template>
                    </Column>
                    <Column field="vence" :header="$t('Vencimiento')" :sortable="true" headerStyle="width: 120px">
                        <template #body="slotProps">
                            {{slotProps.data.vence}}
                        </template>
                    </Column>
                    <Column field="lic" :header="$t('Nº placa')" headerStyle="width: 150px">
                        <template #body="slotProps">
                            {{slotProps.data.placa}}
                        </template>
                    </Column>
                    <Column :header="$t('Prima Total')" headerStyle="width: 180px">
                        <template #body="slotProps">
                            {{money(slotProps.data.money,slotProps.data.monto)}}
                        </template>
                    </Column>
                    <Column field="tipopoliza_en" :header="$t('Cobertura')" headerStyle="width: 150px">
                        <template #body="slotProps">
                            {{slotProps.data['tipopoliza_'+i18n.locale()]}}
                        </template>
                    </Column>
                    <Column :header="$t('Marca')+'/'+$t('Marca')" headerStyle="width: 180px">
                        <template #body="slotProps">
                            {{ slotProps.data.marca }} {{ slotProps.data.model }}
                        </template>
                    </Column>
                    <Column :header="$t('Acciones')" :sortable="false" headerStyle="width: 120px">
                        <template #body="slotProps">
                            <Button :disabled ="$store.state.loading" icon="pi pi-eye" class="p-button-rounded p-button-secondary p-mr-2" @click="openview(slotProps.data)"/>
                            <Button :disabled ="$store.state.loading" icon="pi pi-file-pdf" class="p-button-rounded p-button-primary p-mr-2" @click="VerPDF(slotProps.data.firmado)" v-if="slotProps.data.firmado"/>
                        </template>
                    </Column>
                    <template #footer footerStyle="text-align:right">
                    </template>
                </DataTable>
			</div>
        </div>

        <View :display="displayview" :police="police" :duracion="duracion" :cuotas="cuotas" @aprob-view="onMenuToggle"  @close-view="close"/>
    </div>
</template>

<script>
import API from "../service/API";
const Consulta = new API('Reportes','Report1');
import { useI18nPlugin } from '@unify/vuex-i18n';
import url from "@/service/_URL";
import PDF from "../service/PDF";
import View from '../components/View.vue';
import XLS from "../service/XLS";

export default {
	components: {
        'View': View
    },
	data() {
		return {
			selectedCustomers: null,
			filters: {},
			i18n: null,
            caso: null,
            poliza: [],
			url: null,
            option: {code: 1, nombre: 'Mes', name: 'Month'},
			options: [
				{code: 0, nombre: 'Póliza', name: 'Policy'},
				{code: 1, nombre: 'Mes', name: 'Month'},
				{code: 2, nombre: 'Fecha', name: 'Date'},
			],
			opcion: null,
			opciones: [],
			opcion2: {code: 0,   nombre: 'Todos', name: 'All'},
			opciones2: [{code: 0,   nombre: 'Todos', name: 'All'}],
            year: null,
			mes: null,
            fecha1: null,
            fecha2: null,
            buscar: null,
            polizas: [],
            displayview: false,
            police: {},
            duracion: [],
            cuotas: [],

        }
	},
	productService: null,
	eventService: null,
	created() {
		this.url = url;
        this.year = this.$store.state.year;
		this.mes = this.$store.state.mes;
		this.i18n = useI18nPlugin();
		this.$store.commit('Validar',this.$route);
		if (this.$store.state.mobile){this.size = '90vw';}
        this.Mostrar();		
	},
	mounted() {
	},
	methods: {
        Mostrar(){
            Consulta.Ini().then(result => {
                if (result.consult.opciones){
                    this.opciones = result.consult.opciones;
                }
                if (result.consult.estados){
                    result.consult.estados.forEach(result => {
                        this.opciones2.push(result);
                    });
                }
            }); 
        },
		VerXLS(){
            const xls = new XLS();
            xls.Reporte1(this.poliza);
		},
        VerIMG(data){
			window.open(data, '_blank');
		},
        Buscar(){
            if((this.option.code==1 && this.opcion && this.mes && this.year)
                || (this.option.code==2 && this.opcion && this.fecha1 && this.fecha2)
                || (this.option.code==0 && this.buscar)
            ){
                this.poliza = [];
                Consulta.Procesar('Buscar',{
                    opcion: this.opcion.code,
                    opcion2: this.opcion2.code,
                    option: this.option.code,
					mes: this.mes.code,
					year: this.year.code,
                    fecha1: Consulta.fdate(this.fecha1),
                    fecha2: Consulta.fdate(this.fecha2),
					poliza: this.buscar,
                }).then(response => {
                    //this.$store.state.error = response;
                    if (response.result.poliza){
                        this.poliza = response.result.poliza;
                    }
                });
            }
        },
        Ver(data){
            const doc = new PDF();
            doc.Certificado(data);
        },
        VerPDF(data){
            window.open(url.api+'firmado/'+data, '_blank');
        },
        convfech(fecha){
            return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
		},
		toggle1(event) {
			this.$refs.op1.toggle(event);
		},
		toggle2(event) {
			this.$refs.op2.toggle(event);
		},
		toggle3(event) {
			this.$refs.op3.toggle(event);
		},
        moneda(number){
            return new Intl.NumberFormat("en", {style: "currency", currency: this.$store.state.currency}).format(parseFloat(number).toFixed(2));
		},
        money(currency,number){
            return new Intl.NumberFormat("en", {style: "currency", currency: currency}).format(parseFloat(number).toFixed(2));
		},
        openview(data) {
            this.police = {};
            this.$store.commit('Mobile');
			Consulta.Procesar('Ver',{
                poliza: data.id,
				tipo: data.tipo,
				}).then(response => {
                    this.police = response.result.poliza;
                    this.duracion = response.result.duracion;
                    this.cuotas = response.result.cuotas;
                    this.displayview = true;
                });           
        },
        close() {
            this.displayview = false;
        },
		onMenuToggle(event) {
			this.$emit('menu-toggle', event);
		},

	},
	computed: {

    }

}
</script>
<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>